import { Badge } from '@kiper/ui';
import styled, { css } from 'styled-components';

export const InterlockedPlaceLink = styled(Badge)`
  ${props =>
    props.placeSelected &&
    css`
      &:hover {
        transform: scale(1.02);
        background-color: ${({ theme }) => theme.colors.orange300};
      }
    `}
`;
