import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Text } from '@kiper/ui';
import { doorStatusTypes } from '../../../../../constants';

const TooltipPlaceContent = ({ doorStatus, doorKeepOpenedReason }) => {
  const [t] = useTranslation('guided-attendance');

  switch (doorStatus) {
    case doorStatusTypes.KeepOpened:
      return (
        <>
          <Text fontWeight="600" fontSize="14px" lineHeight="20px">
            {t('step-open-doors.status.kept-open-badge-tooltip')}
          </Text>
          <br />
          <Text fontWeight="400" fontSize="14px" lineHeight="20px">
            {doorKeepOpenedReason}
          </Text>
        </>
      );
    default:
      return null;
  }
};

export default TooltipPlaceContent;

TooltipPlaceContent.propTypes = {
  doorStatus: propTypes.string.isRequired,
  doorKeepOpenedReason: propTypes.string.isRequired,
};
