import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FiAlertCircle, FiAlertTriangle, FiLock } from 'react-icons/fi';
import { Badge, Text, Tooltip } from '@kiper/ui';
import { doorStatusTypes } from '../../../../constants';

const DoorStatus = ({ doorStatus, doorKeepOpenedReason }) => {
  const { t } = useTranslation('guided-attendance');
  switch (doorStatus) {
    case doorStatusTypes.Opened:
      return (
        <Badge color="warning500">
          <FiAlertCircle />
          <Text fontSize="12px">{t('step-open-doors.status.opened')}</Text>
        </Badge>
      );
    case doorStatusTypes.Closed:
      return (
        <Badge color="secondary800">
          <FiLock />
          <Text fontSize="12px">{t('step-open-doors.status.closed')}</Text>
        </Badge>
      );
    case doorStatusTypes.KeepOpened:
      return (
        <>
          <Badge id="keep-opened-status-badge" color="orange500">
            <FiAlertTriangle />
            <Text>{t('step-open-doors.status.kept-open')}</Text>
          </Badge>
          {doorKeepOpenedReason && (
            <Tooltip placement="top" target="keep-opened-status-badge">
              <Text fontWeight="600" fontSize="14px" lineHeight="20px">
                {t('step-open-doors.status.kept-open-badge-tooltip')}
              </Text>
              <br />
              <Text fontWeight="400" fontSize="14px" lineHeight="20px">
                {doorKeepOpenedReason}
              </Text>
            </Tooltip>
          )}
        </>
      );
    default:
      return null;
  }
};

export default DoorStatus;

DoorStatus.propTypes = {
  doorStatus: propTypes.string.isRequired,
  doorKeepOpenedReason: propTypes.string,
};

DoorStatus.defaultProps = {
  doorKeepOpenedReason: null,
};
