import { useCallback } from 'react';
import propTypes from 'prop-types';
import { Grid } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';
import CameraItem from './CameraItem';

import * as S from './styles';

const LIST_HEIGHT = 90;
const ROW_HEIGHT = 78;
const COLUMN_WIDTH = 120;

const CamerasList = ({ cameras, handleClickThumbnail, event }) => {
  if (!cameras?.length) return null;

  const thumbItems = useCallback(
    ({ columnIndex, key, style }) => {
      const camera = cameras[columnIndex];

      return (
        <CameraItem
          key={key}
          camera={camera}
          style={style}
          handleClickThumbnail={handleClickThumbnail}
          event={event}
        />
      );
    },
    [cameras],
  );

  return (
    <S.CameraListContainer>
      <AutoSizer disableHeight>
        {({ width }) => (
          <Grid
            height={LIST_HEIGHT}
            width={width}
            columnCount={cameras.length}
            columnWidth={COLUMN_WIDTH}
            rowCount={1}
            rowHeight={ROW_HEIGHT}
            cellRenderer={thumbItems}
          />
        )}
      </AutoSizer>
    </S.CameraListContainer>
  );
};

export default CamerasList;

CamerasList.propTypes = {
  cameras: propTypes.array,
  event: propTypes.any.isRequired,
  handleClickThumbnail: propTypes.func.isRequired,
};

CamerasList.defaultProps = {
  cameras: [],
};
