import { Button } from '@kiper/ui';
import styled from 'styled-components';

export const CommandButton = styled(Button)`
  background-color: ${({ theme, bg }) => bg && theme.colors[bg]} !important;
`;

export const CallButton = styled(CommandButton)`
  margin-right: 36px;
`;
