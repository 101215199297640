import { useSwal } from '@kiper/hooks';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { adamSender } from '@kiper/monitoring-graphql';

const useDoorCommandsMutations = () => {
  const { t } = useTranslation('guided-attendance');
  const { toast } = useSwal();

  const onError = () => {
    toast.fire({
      title: t('step-open-doors.commands.feedback.error-message'),
      icon: 'error',
    });
  };

  const [openDeviceMutation, { loading: loadingOpenDevice }] = useMutation(
    adamSender.openDevice,
    {
      fetchPolicy: 'no-cache',
      onError,
      onCompleted: () =>
        toast.fire({
          title: t('step-open-doors.commands.feedbacks.open'),
          icon: 'success',
        }),
    },
  );

  const [closeDeviceMutation, { loading: loadingCloseDevice }] = useMutation(
    adamSender.closeDevice,
    {
      fetchPolicy: 'no-cache',
      onError,
      onCompleted: () =>
        toast.fire({
          title: t('step-open-doors.commands.feedbacks.close'),
          icon: 'success',
        }),
    },
  );

  const [
    keepDeviceOpenedMutation,
    { loading: loadingKeepOpenDevice },
  ] = useMutation(adamSender.keepDeviceOpened, {
    fetchPolicy: 'no-cache',
    onError,
    onCompleted: () =>
      toast.fire({
        title: t('step-open-doors.commands.feedbacks.keep-open'),
        icon: 'success',
      }),
  });

  const [
    keepDeviceOpenedV2Mutation,
    { loading: loadingKeepOpenDeviceV2 },
  ] = useMutation(adamSender.keepDeviceOpenedV2, {
    fetchPolicy: 'no-cache',
    onError,
    onCompleted: () =>
      toast.fire({
        title: t('step-open-doors.commands.feedbacks.keep-open'),
        icon: 'success',
      }),
  });

  const loading =
    loadingOpenDevice ||
    loadingCloseDevice ||
    loadingKeepOpenDevice ||
    loadingKeepOpenDeviceV2;

  return {
    openDeviceMutation,
    closeDeviceMutation,
    keepDeviceOpenedMutation,
    keepDeviceOpenedV2Mutation,
    loading,
  };
};

export default useDoorCommandsMutations;
