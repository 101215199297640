import { useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex, MdIcons, Text } from '@kiper/ui';
import DeviceCommand from '../DeviceCommand';
import InterlockedPlaces from '../InterlockedPlaces';
import { Container, ObservationWrapper } from './styles';

const PlaceItem = ({
  place,
  selectedPlace,
  handleSelectedPlace,
  event,
  deviceStatus,
}) => {
  const [t] = useTranslation('guided-attendance');
  const placeRef = useRef({});

  const setRef = (id, node) => {
    placeRef.current[id] = node;
  };

  useEffect(() => {
    if (selectedPlace?.id && placeRef.current[selectedPlace?.id]) {
      placeRef.current[selectedPlace?.id].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [selectedPlace]);

  return (
    <Container
      selected={selectedPlace?.id === place.id}
      ref={node => setRef(place.id, node)}
      onClick={() => handleSelectedPlace(place)}
    >
      {selectedPlace?.id === place?.id ? (
        <Flex width="100%" flexDirection="column" gridGap="8px">
          <Flex gridGap="4px">
            <MdIcons mdIconName="door_front" size="20px" />
            <Text fontWeight="bolder">{place?.name}</Text>
          </Flex>
          {place?.devices?.map(device => (
            <DeviceCommand
              key={device.id}
              device={device}
              selectedPlace={selectedPlace}
              event={event}
              place={place}
              deviceStatus={deviceStatus}
            />
          ))}
          {!!place?.interlockedPlaces?.length && (
            <InterlockedPlaces
              place={place}
              selectedPlace={selectedPlace}
              handleSelectedPlace={handleSelectedPlace}
            />
          )}
          {place?.comments && (
            <ObservationWrapper>
              <Text fontWeight="800" fontSize="12px">
                {t('step-open-doors.observation')}
              </Text>
              <Text fontSize="12px">{place.comments}</Text>
            </ObservationWrapper>
          )}
        </Flex>
      ) : (
        <Flex gridGap="4px">
          <MdIcons mdIconName="door_front" size="20px" />
          <Text fontWeight="bolder">{place?.name}</Text>
        </Flex>
      )}
    </Container>
  );
};

export default PlaceItem;

PlaceItem.propTypes = {
  place: propTypes.object.isRequired,
  selectedPlace: propTypes.object.isRequired,
  handleSelectedPlace: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
  deviceStatus: propTypes.array,
};

PlaceItem.defaultProps = {
  deviceStatus: [],
};
