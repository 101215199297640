import propTypes from 'prop-types';
import { Tooltip } from '@kiper/ui';
import { doorStatusTypes } from '../../../../../constants';
import { useDoorStatus, usePlaceDeviceInfo } from '../../hooks';
import { PlaceStyled } from './styles';
import TooltipPlaceContent from './TooltipPlaceContent';

const CondominiumMapPlace = ({
  place,
  selectedPlace,
  setSelectedPlace,
  event,
  deviceStatus,
  sizeMap,
}) => {
  const { device, coordinate, isOrigin, deviceOnlyAudio } = usePlaceDeviceInfo({
    place,
    sizeMap,
    eventPlaceId: event?.placeId,
  });

  const { iconStatus, doorStatus, doorKeepOpenedReason } = useDoorStatus({
    device,
    deviceStatus,
    isOrigin,
    deviceOnlyAudio,
  });

  if (!coordinate) return null;

  return (
    <>
      <PlaceStyled
        id={`map-place-${place.id}`}
        onClick={() => setSelectedPlace(place)}
        origin={Number(event?.placeId === place.id)}
        coordinate={coordinate}
        selected={selectedPlace?.id === place.id}
        doorStatus={doorStatus}
      >
        {coordinate && iconStatus}
      </PlaceStyled>
      {doorStatus === doorStatusTypes.KeepOpened && doorKeepOpenedReason && (
        <Tooltip placement="top" target={`map-place-${place.id}`}>
          <TooltipPlaceContent
            doorStatus={doorStatus}
            doorKeepOpenedReason={doorKeepOpenedReason}
          />
        </Tooltip>
      )}
    </>
  );
};

export default CondominiumMapPlace;

CondominiumMapPlace.propTypes = {
  place: propTypes.object.isRequired,
  selectedPlace: propTypes.number,
  setSelectedPlace: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
  deviceStatus: propTypes.array.isRequired,
  sizeMap: propTypes.number.isRequired,
};

CondominiumMapPlace.defaultProps = {
  selectedPlace: null,
};
