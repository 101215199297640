import { Flex, Text as text } from '@kiper/ui';
import styled, { css } from 'styled-components';

export const MapContainer = styled.div`
  font-size: 20px;
  border: 1px solid ${props => props.theme.colors.secondary300};
  border-radius: 8px;
  position: relative;
  background-size: cover;
  height: ${props => `${props.size}px`};
  width: ${props => `${props.size}px`};
  ${props =>
    props.url &&
    css`
      background-image: url(${`${props.url}`});
    `}
`;

export const EmptyStateContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const Img = styled.img`
  width: 254px;
`;

export const Title = styled(text)`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: ${props => props.theme.colors.highBlack};
  font-size: 20px;
`;

export const Text = styled(text)`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: ${props => props.theme.colors.highBlack};
  font-size: 14px;
`;
