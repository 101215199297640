import propTypes from 'prop-types';
import { useContext, useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { getGuidedAttendanceViewTemplate } from '@kiper/monitoring-graphql/guided_attendance';
import { withErrorBoundary } from '@kiper/ui';
import {
  EventAttendanceContext,
  GuidedAttendanceContext,
} from '../../../store';
import GuidedAttendanceDetailContent from './GuidedAttendanceDetailContent';
import GuidedAttendanceDetailInfo from './GuidedAttendanceDetailInfo';
import GuidedAttendanceSideMenu from './GuidedAttendanceSideMenu/GuidedAttendanceSideMenu';
import AttendanceFallbackErrorBoundary from '../../../components/AttendanceFallbackErrorBoundary';
import * as S from './styles';

const GuidedAttendanceDetail = ({ socketError, ...props }) => {
  const { eventInAttendance, eventOnHoldInAttendance } = useContext(
    EventAttendanceContext,
  );
  const { contextTree } = useContext(GuidedAttendanceContext);

  const event = eventInAttendance || eventOnHoldInAttendance;

  const { data, loading } = useQuery(getGuidedAttendanceViewTemplate, {
    fetchPolicy: 'cache-first',
  });

  const viewTemplateByEventType = eventType =>
    data?.guidedAttendanceViewTemplate?.find(
      template => template.eventType === eventType,
    );

  const viewTemplate = useMemo(
    () =>
      viewTemplateByEventType(event?.eventType) ??
      viewTemplateByEventType(null),
    [event, data],
  );

  return (
    <S.Container>
      <GuidedAttendanceDetailContent event={event} {...props} />
      {!contextTree?.hideAttendanceDetailInfo && (
        <GuidedAttendanceDetailInfo
          componentName={viewTemplate?.detailInfo?.componentName}
          loading={loading}
          event={event}
        />
      )}
      <GuidedAttendanceSideMenu
        event={event}
        viewTemplate={viewTemplate}
        loadingViewTemplate={loading}
      />
    </S.Container>
  );
};

GuidedAttendanceDetail.propTypes = {
  match: propTypes.object.isRequired,
  socketError: propTypes.object,
};

GuidedAttendanceDetail.defaultProps = {
  socketError: null,
};

const GuidedAttendanceDetailComponent = withErrorBoundary(
  GuidedAttendanceDetail,
  {
    CustomErrorComponent: props => (
      <AttendanceFallbackErrorBoundary
        backToRoute="/guided-attendance"
        {...props}
      />
    ),
  },
);

export default GuidedAttendanceDetailComponent;
