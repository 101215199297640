import { Flex } from '@kiper/ui';
import styled from 'styled-components';

export const Container = styled(Flex)`
  flex-direction: column;
  gap: 8px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: ${props => props.maxheight};
`;
