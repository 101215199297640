import { useEffect, useState } from 'react';
import {
  MdKeyboardVoice,
  MdOutlineDoorBack,
  MdOutlinePlace,
} from 'react-icons/md';
import { RiDoorOpenLine } from 'react-icons/ri';
import { doorStatusTypes } from '../../../../constants';

const OTHER_DEVICE_STATUS = {
  isOrigin: 'isOrigin',
  isOnlyAudio: 'isOnlyAudio',
};

const useDoorStatus = ({
  deviceStatus,
  device,
  isOrigin,
  deviceIsOnlyAudio,
}) => {
  const [iconStatus, setIconStatus] = useState(<MdOutlineDoorBack />);
  const [doorStatus, setDoorStatus] = useState(null);
  const [doorKeepOpenedReason, setDoorKeepOpenedReason] = useState(null);

  useEffect(() => {
    const hasStatus = deviceStatus?.find(
      x =>
        x.serialNumber === device?.serialNumber &&
        x.messageData.doorId === device?.doorId,
    );

    setDoorStatus(hasStatus?.messageData?.doorStatus || device?.doorStatus);
    setDoorKeepOpenedReason(
      hasStatus?.messageData?.keepOpenedReason || device?.keepOpenedReason,
    );
  }, [device, deviceStatus]);

  useEffect(() => {
    let status = doorStatus;
    if (isOrigin) status = OTHER_DEVICE_STATUS.isOrigin;
    else if (deviceIsOnlyAudio) status = OTHER_DEVICE_STATUS.isOnlyAudio;

    const iconOptions = {
      [OTHER_DEVICE_STATUS.isOrigin]: <MdOutlinePlace size={14} />,
      [OTHER_DEVICE_STATUS.isOnlyAudio]: <MdKeyboardVoice size={14} />,
      [doorStatusTypes.Closed]: <MdOutlineDoorBack size={14} />,
      [doorStatusTypes.Opened]: <RiDoorOpenLine size={14} />,
      [doorStatusTypes.KeepOpened]: <RiDoorOpenLine size={14} />,
    };

    if (status) {
      setIconStatus(iconOptions[status]);
    }
  }, [isOrigin, doorStatus, deviceIsOnlyAudio]);

  return {
    iconStatus,
    doorStatus,
    doorKeepOpenedReason,
  };
};

export default useDoorStatus;
