import propTypes from 'prop-types';
import * as S from './styles';
import CameraThumbnail from '../CamerasGrid/CameraThumbnail';

const CameraItem = ({ camera, style, handleClickThumbnail, event }) => {
  return (
    <S.CameraListThumbContainer style={style}>
      <CameraThumbnail
        camera={camera}
        handleClickThumbnail={() => handleClickThumbnail(camera)}
        condominiumPersonContextId={event?.condominium?.personContext?.id}
        date={null}
      />
    </S.CameraListThumbContainer>
  );
};

CameraItem.propTypes = {
  camera: propTypes.object.isRequired,
  style: propTypes.object.isRequired,
  handleClickThumbnail: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
};

export default CameraItem;
