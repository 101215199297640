import { Trans } from 'react-i18next';
import propTypes from 'prop-types';
import { AlertBar, Divider, Flex, MdIcons } from '@kiper/ui';
import { ReturnStepButton, NextStepButton } from './ActionStepButtons';
import { Container } from './styles';

export const StepTextTypeColor = Object.freeze({
  SPEAK: {
    background: 'guidedAttendance.text.talk',
    icon: <MdIcons mdIconName="forum" size="20px" />,
  },
  VERIFY: {
    background: 'guidedAttendance.text.action',
    icon: <MdIcons mdIconName="person_search" size="20px" />,
  },
  TAKE_PHOTO: {
    background: 'guidedAttendance.text.action',
    icon: <MdIcons mdIconName="photo_camera" size="20px" />,
  },
  OPEN_DOORS: {
    background: 'guidedAttendance.text.action',
    icon: <MdIcons mdIconName="touch_app" size="20px" />,
  },
  WARNING: {
    background: 'guidedAttendance.text.warning',
    icon: <MdIcons mdIconName="warning" size="20px" />,
  },
});

/**
 * Step Structure Component.
 *
 * @property {StepTextTypeColor} type - type is a object to define background and icon
 * @property {string} text - text to be displayed in the alert bar
 * @property {element} children - children to be displayed in the step
 * @property {boolean} hasNextStepButton - boolean to show or not the next step button
 * @property {boolean} hasPreviousStepButton - boolean to show or not the previous step button
 * @default [hasNextStepButton=false]
 * @default [hasPreviousStepButton=false]
 */
const StepStructure = ({
  type,
  text,
  children,
  hasNextStepButton,
  hasPreviousStepButton,
}) => {
  return (
    <Container>
      <Flex
        width="100%"
        flexDirection="column"
        alignItems="flex-start"
        marginTop={!hasPreviousStepButton && '30px'}
      >
        {hasPreviousStepButton && <ReturnStepButton />}
        <AlertBar
          width="100%"
          color="black"
          background={type.background}
          title={<Trans>{text}</Trans>}
          icon={type.icon}
        />
      </Flex>
      <Divider />
      {children}
      {hasNextStepButton && <NextStepButton />}
    </Container>
  );
};

export default StepStructure;

StepStructure.propTypes = {
  type: propTypes.shape({
    background: propTypes.string,
    icon: propTypes.element,
  }).isRequired,
  text: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
  hasNextStepButton: propTypes.bool,
  hasPreviousStepButton: propTypes.bool,
};

StepStructure.defaultProps = {
  hasNextStepButton: false,
  hasPreviousStepButton: false,
};
