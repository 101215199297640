import { Flex } from '@kiper/ui';
import styled, { css } from 'styled-components';

export const Container = styled(Flex)`
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  gap: 8px;
  padding: 8px;
  border: 1px solid
    ${({ selected, theme }) =>
      selected ? theme.colors.primary500 : theme.colors.secondary300};
  border-radius: 4px;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.primary50 : theme.colors.white};

  ${props =>
    !props.selected &&
    css`
      &:hover {
        background-color: ${props.theme.colors.secondary50};
      }
    `}
`;

export const ObservationWrapper = styled(Flex)`
  padding: 4px;
  background-color: ${props => props.theme.colors.info100};
  border: 1px solid ${props => props.theme.colors.info500};
  border-radius: 4px;
  flex-direction: column;
`;
