import { Flex } from '@kiper/ui';
import styled, { css } from 'styled-components';

export const ImgContainer = styled(Flex)`
  position: relative;
  background-color: ${props => props.theme.colors.gray900};
  border-radius: 8px;
  ${props =>
    css`
      &:before {
        ${!props.loading && ` content: "${props.live}";`}
        text-transform: uppercase;
        color: ${props.theme.colors.white};
        background: ${props.theme.colors.danger500};
        border-radius: 8px;
        padding: 4px 8px;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0;
        align-items: center;
        z-index: 1;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    `}
`;

export const VideoImg = styled.img`
  width: 100%;
  margin: 0 auto;
  border-radius: 8px;
  object-fit: contain;
  background: ${({ theme }) => theme.colors.gray900};
  display: ${({ loading, offline }) => (loading || offline ? 'none' : 'block')};

  @media screen and (min-width: 1366px) {
    height: 242px;
  }

  @media screen and (min-width: 1525px) {
    height: 280px;
  }
`;

export const Wrapper = styled(Flex)`
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, bg }) => bg || theme.colors.gray900};

  @media screen and (min-width: 1366px) {
    height: 242px;
  }

  @media screen and (min-width: 1525px) {
    height: 280px;
  }
`;
