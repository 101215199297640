import { useLayoutEffect, useRef, useState } from 'react';
import { useWindowDimensions } from '@kiper/hooks';

const useMaxDimensions = ({ heightToBeDiscounted = 0 }) => {
  const elementRef = useRef();
  const [maxHeight, setMaxHeight] = useState(null);
  const [maxWidth, setMaxWidth] = useState(null);
  const windowDimensions = useWindowDimensions();

  useLayoutEffect(() => {
    const element = elementRef.current;
    if (element) {
      const { height, bottom, width, right } = element.getBoundingClientRect();
      const overflowHeightDiff = bottom - windowDimensions.height;
      const overflowWidthDiff = right - windowDimensions.width;
      const newMaxHeight = height - overflowHeightDiff - heightToBeDiscounted;
      const newMaxWidth = width - overflowWidthDiff;

      if (newMaxHeight) setMaxHeight(`${newMaxHeight}px`);
      if (newMaxWidth) setMaxWidth(`${newMaxWidth}px`);
    }
  }, [elementRef.current, windowDimensions]);

  return { maxHeight, maxWidth, elementRef };
};

export default useMaxDimensions;
