import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@kiper/hooks';
import { MdIcons } from '@kiper/ui';
import { DOOR_COMMANDS } from '../../../../constants';
import useDoorCommandsMutations from './useDoorCommandsMutations';

const useDoorCommands = ({ device, event }) => {
  const [
    openReasonKeepOpenDoorModal,
    toggleReasonKeepOpenDoorModal,
  ] = useToggle(false);
  const { t } = useTranslation('guided-attendance');

  const {
    openDeviceMutation,
    closeDeviceMutation,
    keepDeviceOpenedMutation,
    keepDeviceOpenedV2Mutation,
    loading,
  } = useDoorCommandsMutations();

  const { hasOpenDoorCommand, otherCommands } = useMemo(
    () => ({
      hasOpenDoorCommand: device?.commands?.some(
        command => command === DOOR_COMMANDS.OPEN,
      ),
      otherCommands: device?.commands?.filter(
        command => command !== DOOR_COMMANDS.OPEN,
      ),
    }),
    [device],
  );

  const handleDoorCommand = async (
    command,
    deviceId,
    doorId,
    reason = null,
  ) => {
    const mutationKeepDeviceOpened = reason
      ? keepDeviceOpenedV2Mutation
      : keepDeviceOpenedMutation;

    const variables = {
      deviceHandler: {
        deviceId,
        doorId,
        eventId: event?.eventId,
      },
    };

    switch (command) {
      case DOOR_COMMANDS.OPEN:
        await openDeviceMutation({ variables });
        break;
      case DOOR_COMMANDS.CLOSE:
        await closeDeviceMutation({ variables });
        break;
      case DOOR_COMMANDS.KEEP_OPEN:
        await mutationKeepDeviceOpened({
          variables: {
            deviceHandler: {
              ...variables.deviceHandler,
              keepOpenedType: 0,
              ...(reason && { reason }),
            },
          },
        });
        break;
      case DOOR_COMMANDS.TRUCK:
        await keepDeviceOpenedMutation({
          variables: {
            deviceHandler: {
              ...variables.deviceHandler,
              keepOpenedType: 1,
            },
          },
        });
        break;
      case DOOR_COMMANDS.CLEANING:
        await keepDeviceOpenedMutation({
          variables: {
            deviceHandler: {
              ...variables.deviceHandler,
              keepOpenedType: 2,
            },
          },
        });
        break;
      default:
        break;
    }
  };

  const handleSendKeepOpenReason = data => {
    handleDoorCommand(
      DOOR_COMMANDS.KEEP_OPEN,
      device.id,
      device.doorId,
      data.reason,
    );
    toggleReasonKeepOpenDoorModal();
  };

  const handleGetCommandsButton = deviceCommands => {
    const commands = [];
    deviceCommands.forEach(command => {
      switch (command) {
        case DOOR_COMMANDS.OPEN:
          commands.push({
            action: () =>
              handleDoorCommand(DOOR_COMMANDS.OPEN, device.id, device.doorId),
            label: t('step-open-doors.commands.open'),
            icon: <MdIcons mdIconName="door_open" size="20px" />,
          });
          break;
        case DOOR_COMMANDS.CLOSE:
          commands.push({
            action: () =>
              handleDoorCommand(DOOR_COMMANDS.CLOSE, device.id, device.doorId),
            label: t('step-open-doors.commands.close'),
            icon: <MdIcons mdIconName="door_front" size="20px" />,
          });
          break;
        case DOOR_COMMANDS.KEEP_OPEN:
          commands.push({
            action: () => toggleReasonKeepOpenDoorModal(),
            label: t('step-open-doors.commands.keep-open'),
            icon: <MdIcons mdIconName="door_open" size="20px" />,
          });
          break;
        case DOOR_COMMANDS.TRUCK:
          commands.push({
            action: () =>
              handleDoorCommand(DOOR_COMMANDS.TRUCK, device.id, device.doorId),
            label: t('step-open-doors.commands.keep-open-truck'),
            icon: <MdIcons mdIconName="local_shipping" size="20px" />,
          });
          break;
        case DOOR_COMMANDS.CLEANING:
          commands.push({
            action: () =>
              handleDoorCommand(
                DOOR_COMMANDS.CLEANING,
                device.id,
                device.doorId,
              ),
            label: t('step-open-doors.commands.keep-open-cleaning'),
            icon: <MdIcons mdIconName="cleaning_services" size="20px" />,
          });
          break;
        default:
          break;
      }
    });

    return commands;
  };

  return {
    handleGetCommandsButton,
    handleDoorCommand,
    hasOpenDoorCommand,
    otherCommands,
    loading,
    openReasonKeepOpenDoorModal,
    toggleReasonKeepOpenDoorModal,
    handleSendKeepOpenReason,
  };
};

export default useDoorCommands;
